import '../../sass/blocks/block-home-scroll.scss';
/* eslint-disable */
import lottie from 'lottie-web';

let lottieAnimation = {
    first: null,
    second: null,
    third: null
}
const isPortrait = () =>   window.innerWidth < window.innerHeight;
let lotties = {
    first: null,
    second: null,
    third: null,
}
let active = null;
const lottieSegments = {
    first: [0, 110],
    second: [0, 110],
    third: [0, 110]
}
function slide1() {
    if(lottieAnimation.first) return
    const lottieItem = lottie.loadAnimation({
        container: document.querySelector('.lottie1'), // required
        path: document.querySelector(".lottie1").getAttribute(`data-src-${isPortrait() ? "mobile" : "desktop"}`), // required
        renderer: 'canvas', // required
        loop: true, // optional
        autoplay: true, // optional
        name: "first", // optional
    });
    lottieAnimation.first = true;
    lotties.first = lottieItem;
    window.first = lottieItem;

}
function slide2() {
    if(lottieAnimation.second) return
    const lottieItem  = lottie.loadAnimation({
        container: document.querySelector('.lottie2'), // required
        path: document.querySelector(".lottie2").getAttribute(`data-src-${isPortrait() ? "mobile" : "desktop"}`), // required
        renderer: 'canvas', // required
        loop: true, // optional
        autoplay: true, // optional
        name: "second", // optional
    });
    lottieAnimation.second = true;
    lotties.second = lottieItem;
    window.second = lottieItem;


}
function slide3() {
    if(lottieAnimation.third) return
    const lottieItem  = lottie.loadAnimation({
        container: document.querySelector('.lottie3'), // required
        path: document.querySelector(".lottie3").getAttribute(`data-src-${isPortrait() ? "mobile" : "desktop"}`), // required
        renderer: 'canvas', // required
        loop: true, // optional
        autoplay: true, // optional
        name: "third", // optional
    });
    lottieAnimation.third = true;
    lotties.third = lottieItem;
    window.third = lottieItem;


}
function startLottie(container, name) {
    if(active === name) {
        return
    }
    else if(!lotties[name]) {
        active = name;
        const lottieItem  = lottie.loadAnimation({
            container: document.querySelector(container), // required
            path: document.querySelector(container).getAttribute(`data-src-${isPortrait() ? "mobile" : "desktop"}`), // required
            renderer: 'canvas',
            loop: false,
            autoplay: false,
            name,
        });
        lotties[name] = lottieItem;
        lottieItem.addEventListener('DOMLoaded', function(){
            lottieItem.playSegments(lottieSegments[name], true);
        });
    }
    else {
        active = name;
        lotties[name].playSegments(lottieSegments[name], true)
    }

}

/*
const lottieNames = ["first", "second", "third"]
const lotties = []
window.lottie = lottie;
function initLotties(orientation) {
    const firstContainer = document.querySelector(".lottie1");
    const secondContainer = document.querySelector(".lottie2");
    const thirdContainer = document.querySelector(".lottie3");
    const containers = [firstContainer, secondContainer, thirdContainer];
    containers.forEach((container, i) => {
        const lottieItem = lottie.loadAnimation({
            container,
            path: container.getAttribute(`data-src-${orientation}`),
            renderer: 'canvas',
            loop: true, 
            autoplay: true, 
            name: lottieNames[i], 
        });
        lotties.push(lottieItem);
/!*        if(orientation === "desktop") {
            lottieItem.addEventListener('DOMLoaded', function() {
                lottie.pause(lottieNames[i]);

            });
        }*!/
    })
}*/

function playLottie(name, cb) {
    Object.entries(lotties).forEach(([key, value]) => {
        if(key !== name && value) {
            value.pause(key)
        }
    })
    if(lotties[name]) {
        lotties[name].play(name)
    }else {
        cb()
    }
    active = name;
}
export function changeSlides() {
    if(scrollY < innerHeight) {
        document.querySelector(".slide1").style.display = "flex";
        document.querySelector(".slide2").style.display = "none";
        document.querySelector(".slide3").style.display = "none";

    }
     else if(scrollY > innerHeight && scrollY < innerHeight + 500) {

        document.querySelector(".slide1").style.display = "flex";
        document.querySelector(".slide2").style.display = "none";
        document.querySelector(".slide3").style.display = "none";
        startLottie(".lottie1", "first")
    }
    else if(scrollY > innerHeight + 500 && scrollY < innerHeight + 1000) {
        document.querySelector(".slide1").style.display = "none";
        document.querySelector(".slide2").style.display = "flex";
        document.querySelector(".slide3").style.display = "none";
        startLottie(".lottie2", "second")
    }
    else if(scrollY > innerHeight + 1000 && scrollY < innerHeight + 1500) {
        document.querySelector(".slide1").style.display = "none";
        document.querySelector(".slide2").style.display = "none";
        document.querySelector(".slide3").style.display = "flex";
         startLottie(".lottie3", "third")

    }
    else if(scrollY > innerHeight + 1500) {
        document.querySelector(".slide1").style.display = "none";
        document.querySelector(".slide2").style.display = "none";
        document.querySelector(".slide3").style.display = "flex";
        startLottie(".lottie3", "third")

    }
}

export function stopLotties() {
    if(innerWidth < innerHeight) {
        Object.entries(lotties).forEach(([key, value]) => {
            value.pause(key)
        })
    }

}
export function resumeLottie() {
    if(innerWidth < innerHeight) {
        Object.entries(lotties).forEach(([key, value]) => {
            value.play(key)
        })
    }

}
function scrollHandler() {
    const container = document;
    changeSlides(container);

}
function forLandscape() {
    Object.values(lotties).forEach(lottie => {
        lottie && lottie.destroy()
    })
    lotties = {
        first: null,
        second: null,
        third: null,
    }
    active = null
    const container = document;
    setTimeout(() => {
        changeSlides(container);
        document.addEventListener("scroll", scrollHandler)
    }, 400)
}
function forPortrait() {
    Object.values(lotties).forEach(lottie => {
        lottie && lottie.destroy()
    })
    lotties = {
        first: null,
        second: null,
        third: null,
    }
    active = null
    lottieAnimation = {
        first: null,
        second: null,
        third: null
    }
    document.removeEventListener("scroll", scrollHandler)
    document.querySelector(".slide1").style.display = "flex";
    document.querySelector(".slide2").style.display = "flex";
    document.querySelector(".slide3").style.display = "flex";
    slide1();
    slide2();
    slide3();

}
window.addEventListener("load", () => {
    if(window.location.pathname === "/" || window.location.pathname === "/simplex/"  || window.location.pathname === "/simplex/he/"|| window.location.pathname === "/he/") {
        if( window.innerWidth > window.innerHeight) {
            forLandscape()

        }
        else {
            forPortrait()
        }
    }

    let lastOrientationIsPortrait;

    function checkOrientation() {

        const isPortrait = window.innerHeight > window.innerWidth;


        if (isPortrait !== lastOrientationIsPortrait) {
            if(window.location.pathname === "/" || window.location.pathname === "/simplex/"  || window.location.pathname === "/simplex/he/"|| window.location.pathname === "/he/") {
                if( window.innerWidth > window.innerHeight) {
                    forLandscape()

                }
                else {
                    forPortrait()
                }
            }

            lastOrientationIsPortrait = isPortrait;
        }
    }

    checkOrientation();
    window.addEventListener('resize', checkOrientation);

})
