function toggleSection() {
    const button = document.querySelector('.btn-toggle');
    const scrollDetection1 = document.querySelector('.scroll-detection[data-position="section-1"]');
    const scrollDetection2 = document.querySelector('.scroll-detection[data-position="section-2"]');
    button &&
        button.addEventListener('click', () => {
            if (button.classList.contains('active')) {
                button.classList.remove('active');
                scrollDetection1 && scrollDetection1.scrollIntoView({ block: 'center' });
            }
            else {
                button.classList.toggle('active');
                scrollDetection2 && scrollDetection2.scrollIntoView({ block: 'center' });
            }
        });
}
export default toggleSection;
