function layoutsMapping() {
    const containerMaxWidth = 1420;
    const defaultHeight = 740;
    const containerMap = document.getElementById('interactive_layouts__element');
    const centerWrapper = document.querySelector('.center_wrapper');
    const windowWidth = window.innerWidth;
    function scaleContainerOnResize() {
        const scaleContainer = () => {
            if (windowWidth < containerMaxWidth && windowWidth > 768) {
                const scaleFactor = windowWidth / containerMaxWidth;
                if (containerMap) {
                    containerMap.style.transform = `scale(${scaleFactor})`;
                    centerWrapper.style.height = defaultHeight * scaleFactor + 'px';
                }
            }
            else {
                if (containerMap) {
                    containerMap.style.marginLeft = '0';
                }
            }
        };
        scaleContainer();
        window.addEventListener("resize", scaleContainer);
    }
    if (containerMap) {
        containerMap.classList.add('loaded');
        scaleContainerOnResize();
    }
    const areas = document.querySelectorAll('.element');
    const areasCircle = document.querySelectorAll('.circle');
    const tooltipPoints = document.querySelectorAll('.tooltip-point');
    let currentIndex = 0;
    let timer;
    document.addEventListener('click', (event) => {
        const targetElement = event.target;
        let isClickOutsideAreas = true;
        areas.forEach((area) => {
            if (area.contains(targetElement)) {
                isClickOutsideAreas = false;
            }
        });
        let isClickOutsideTooltipPoints = true;
        tooltipPoints.forEach((tooltipPoint) => {
            if (tooltipPoint.contains(targetElement)) {
                isClickOutsideTooltipPoints = false;
            }
        });
        if (isClickOutsideAreas && isClickOutsideTooltipPoints) {
            startCycling();
        }
    });
    function findNextElementWithClass(startElement, className) {
        let nextElement = startElement.nextElementSibling;
        while (nextElement) {
            if (nextElement.classList.contains(className)) {
                return nextElement;
            }
            nextElement = nextElement.nextElementSibling;
        }
        return null;
    }
    function findPreviousElementWithClass(startElement, className) {
        let prevElement = startElement.previousElementSibling;
        while (prevElement) {
            if (prevElement.classList.contains(className)) {
                return prevElement;
            }
            prevElement = prevElement.previousElementSibling;
        }
        return null;
    }
    function addActiveClass() {
        if (areasCircle && areasCircle.length > 0 && areasCircle[currentIndex]) {
            let tooltip = areasCircle[currentIndex];
            let tooltipPoint = findNextElementWithClass(areasCircle[currentIndex], 'tooltip-point');
            let groupedElements = tooltip === null || tooltip === void 0 ? void 0 : tooltip.dataset.group;
            if (tooltip) {
                tooltip.classList.add('active');
                tooltipPoint.classList.add('active');
                if (groupedElements) {
                    const elements = document.querySelectorAll(`[data-group="${groupedElements}"]`);
                    elements.forEach((element) => {
                        element.classList.add('active');
                    });
                }
            }
        }
    }
    function removeActiveClass() {
        if (areasCircle && areasCircle.length > 0 && areasCircle[currentIndex]) {
            let tooltip = areasCircle[currentIndex];
            let tooltipPoint = findNextElementWithClass(areasCircle[currentIndex], 'tooltip-point');
            let groupedElements = tooltip === null || tooltip === void 0 ? void 0 : tooltip.dataset.group;
            if (tooltip) {
                tooltip.classList.remove('active');
                tooltipPoint.classList.remove('active');
                if (groupedElements) {
                    const elements = document.querySelectorAll(`[data-group="${groupedElements}"]`);
                    elements.forEach((element) => {
                        element.classList.remove('active');
                    });
                }
            }
        }
    }
    function cycleElements() {
        removeActiveClass();
        currentIndex = (currentIndex + 1) % areasCircle.length;
        addActiveClass();
    }
    function startCycling() {
        clearInterval(timer);
        addActiveClass();
        timer = setInterval(cycleElements, 5000);
    }
    function stopCycling() {
        removeActiveClass();
        clearInterval(timer);
    }
    if (windowWidth < 769) {
        tooltipPoints.forEach(tooltipPoint => {
            tooltipPoint.addEventListener('click', (e) => {
                e.preventDefault();
                stopCycling();
                areas.forEach(area => {
                    area.classList.remove('active');
                });
                const previousTooltip = findPreviousElementWithClass(tooltipPoint, 'element');
                if (previousTooltip) {
                    previousTooltip.classList.add('active');
                }
            });
        });
    }
    areas.forEach(function (area) {
        area.addEventListener('mouseenter', function (event) {
            event.preventDefault();
            if (windowWidth > 769) {
                stopCycling();
                let tooltip = event.target;
                let groupedElements = tooltip === null || tooltip === void 0 ? void 0 : tooltip.dataset.group;
                if (tooltip) {
                    tooltip.classList.add('active');
                    if (groupedElements) {
                        const elements = document.querySelectorAll(`[data-group="${groupedElements}"]`);
                        elements.forEach((element) => {
                            element.classList.add('active');
                        });
                    }
                }
                function tooltipLeaveListener(event) {
                    const activeShapes = document.querySelectorAll('.element');
                    event.target.classList.remove('active');
                    activeShapes.forEach(shape => {
                        shape.classList.remove('active');
                    });
                    event.target.removeEventListener('mouseleave', tooltipLeaveListener);
                    startCycling();
                }
                tooltip && tooltip.addEventListener('mouseleave', tooltipLeaveListener);
            }
        });
        area.addEventListener('click', function (event) {
            event.preventDefault();
        });
    });
    startCycling();
}
export default layoutsMapping;
