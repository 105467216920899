/* eslint-disable */
import gsap from "gsap";
export function create3dCarousel() {
    let xPos = 0;
    window.addEventListener("load", () => {
        const containerSlide = document.querySelector(".carousel-3d-js");
        const containerSlides = document.querySelector(".carousel-3d-js .slides");
        const baseSlides = document.querySelectorAll(".carousel-3d-js .slides .slide");
        const arrowRight = document.querySelector(".arrow-right");
        const arrowLeft = document.querySelector(".arrow-left");
        const duplicated = [];

        if(!containerSlide) {
            return
        }
        if(arrowRight) {
            document.querySelector(".arrow-right").addEventListener('click', () => {
                const currentValue =  containerSlides.style.transform.match(/rotateY\((.*?)deg\)/) || 0;
                containerSlides.style.transform = currentValue ?
                    containerSlides.style.transform.replace(/rotateY\((.*?)deg\)/, `rotateY(${+currentValue[1] + 30}deg)` ) : containerSlides.style.transform + `rotateY(${+currentValue + 30}deg)`
            })
        }
        if(arrowLeft) {
            document.querySelector(".arrow-left").addEventListener('click', () => {
                const currentValue =  containerSlides.style.transform.match(/rotateY\((.*?)deg\)/) || 0;
                containerSlides.style.transform = currentValue ?
                    containerSlides.style.transform.replace(/rotateY\((.*?)deg\)/, `rotateY(${+currentValue[1] - 30}deg)` ) : containerSlides.style.transform + `rotateY(${+currentValue - 30}deg)`
            })
        }
        if(innerWidth > innerHeight) {
            function addSlides() {
                if(duplicated.length + baseSlides.length < 12) {
                    duplicated.push(...baseSlides);
                }else {
                    return
                }
                addSlides();
            };
            addSlides();
            duplicated.slice(0, 12 - baseSlides.length).forEach((el) => {
                containerSlides.appendChild(el.cloneNode(true))
            } )

            gsap.timeline()
                .set(".carousel-3d-js .slides", { rotationZ: 5})
                .set('.carousel-3d-js .slide',  {
                    rotateY: (i)=> i*-30,
                    transformOrigin: `50% 50% ${innerWidth * 1}px`,
                    z: -innerWidth * 1    ,
                    backfaceVisibility:'hidden',
                })


        }else {
            const dots = [... (new Array(baseSlides.length).fill(1).map((el, i) => i))];
            function addSlides() {
                if(duplicated.length + baseSlides.length < 12) {
                    duplicated.push(...baseSlides);
                    dots.push(... (new Array(baseSlides.length).fill(1).map((el, i) => i)))
                }else {
                    return
                }
                addSlides()
            };
            addSlides();
            baseSlides.forEach((el, i) => {
                const dots = document.querySelector(".carousel-3d-js .dots")
                const dot = document.createElement("div");
                dot.classList.add("dot");
                dot.setAttribute("data-index", i);
                dots.appendChild(dot);

            })
            duplicated.slice(0, 10 - baseSlides.length).forEach((el) => {
                containerSlides.appendChild(el.cloneNode(true))
            } )
            const formatedDots  = dots.slice(0, 10).map((el, i) => ({
                number: el,
                deg: (deg) => {
                    const formatedDeg = deg >= 0 ?  deg % 360 : 360 -  Math.abs(deg) % 360;
                    return formatedDeg >= (i * 36) - 5 && formatedDeg < (i + 1) * 36 - 5
                }
            }))
            let last = null;

            setInterval(() => {
                const currentValue =  containerSlides.style.transform.match(/rotateY\((.*?)deg\)/);
                if(currentValue) {
                    for(const dot of formatedDots) {
                        if(dot.deg(currentValue[1])) {
                            const target =   document.querySelector(`.dot[data-index="${dot.number}"]`)
                            if(last && last !== target) {
                                last.classList.remove("dot-active")
                            }
                            target.classList.add("dot-active");
                            last = target;
                            return
                        }
                    }
                }else {
                    const target =    document.querySelector(`.dot[data-index="${0}"]`)
                    target.classList.add("dot-active");
                    last = target
                }

            }, 100)

            gsap.timeline()
                .set(".carousel-3d-js .slides", { rotationZ: 5})
                .set('.carousel-3d-js .slide',  {
                    rotateY: (i)=> i*-36,
                    transformOrigin: `50% 50% ${innerWidth * 1.5}px`,
                    z: -innerWidth  * 1.5 ,
                    backfaceVisibility:'hidden',
                })

            jQuery('.carousel-3d-js .slides').on('mousedown touchstart', dragStart);
            jQuery('.carousel-3d-js .slides').on('mouseup touchend', dragEnd);


            function dragStart(e){
                if (e.touches) e.clientX = e.touches[0].clientX;
                xPos = Math.round(e.clientX);
                gsap.set('.slides', {cursor:'grabbing'})
                jQuery('.carousel-3d-js .slides').on('mousemove touchmove', drag);
            }


            function drag(e){
                if (e.touches) e.clientX = e.touches[0].clientX;

                gsap.to('.carousel-3d-js .slides', {
                    rotationY: '-=' +( (Math.round(e.clientX)-xPos)%360 ),
                });

                xPos = Math.round(e.clientX);
            }


            function dragEnd(e){
                jQuery('.carousel-3d-js .slides').off('mousemove touchmove', drag);
                gsap.set('.carousel-3d-js .slides', {cursor:'grab'});
            }


            function getBgPos(i){ //returns the background-position string to create parallax movement in each image
                return ( 100-gsap.utils.wrap(0,360,gsap.getProperty('.carousel-3d-js .slides', 'rotationY')-180-i*36)/360*500 )+'px 0px';
            }
        }

    })
}
