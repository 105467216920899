import Swiper, { Navigation, Pagination } from 'swiper';
let mySwiper;
const enableSwiper = function teamCarousel() {
    mySwiper = new Swiper('.swiper', {
        modules: [Navigation, Pagination],
        loop: false,
        slidesPerView: 'auto',
        centeredSlides: false,
        grabCursor: true,
    });
};
function pricingBlocks() {
    const scrollContainers = document.querySelectorAll('.scroll-container');
    const packageTabInner = document.querySelectorAll('.package__tab-inner');
    if (window.innerWidth > 760) {
        scrollContainers.forEach((scrollContainer) => {
            const packageDivs = scrollContainer.querySelectorAll('.package');
            const firstPackageDiv = packageDivs[0];
            const clonedPackageDiv = firstPackageDiv.cloneNode(true);
            clonedPackageDiv.classList.add('cloned');
            const swiperWrapperDiv = document.createElement('div');
            swiperWrapperDiv.classList.add('swiper-wrapper');
            const swiperDiv = document.createElement('div');
            swiperDiv.classList.add('swiper');
            swiperDiv.appendChild(swiperWrapperDiv);
            const divContainer = document.createElement('div');
            divContainer.classList.add('scroll-wrapper');
            packageDivs.forEach((packageDiv) => {
                swiperWrapperDiv.appendChild(packageDiv);
                packageDiv.classList.add('swiper-slide');
            });
            scrollContainer.insertBefore(swiperDiv, scrollContainer.firstElementChild);
            scrollContainer.insertBefore(clonedPackageDiv, swiperDiv);
            return enableSwiper();
        });
        packageTabInner.forEach((packageTabInner) => {
            const packageDivs = packageTabInner.querySelectorAll('.package');
            packageDivs.forEach((packageDiv) => {
                const hasPriceArea = packageDiv.querySelector('.package__price-area.on-sale');
                const hasDescription = packageDiv.querySelector('.package__description');
                if (hasPriceArea) {
                    packageTabInner.classList.add('has-on-sale');
                }
                if (hasDescription) {
                    packageTabInner.classList.add('has-description');
                }
            });
        });
    }
}
export default pricingBlocks;
