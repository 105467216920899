/* eslint-disable */
import {stopLotties, changeSlides, playLottie, resumeLottie} from "../blocks/block-home-scroll";
function getTempHeightDueOrientation() {
    return innerWidth > 1023 ? 65 : 48
}

const isRtl = document.body.classList.contains('rtl');
const framesNum = 10;
let activeFrame = 0;
const configurationsLandscapeRtl = {
    transform: {
        rotateXDown: [60, 50, 40, 30, 0, -30, -40, -50, -60],
        rotateXUp: [50, 40, 30, 0, -30, -40, -50, -60],
        fontSizeDown: [30, 40, 50, 60, 75, 60, 50, 40, 30],
        fontSizeUp: [40, 50, 60, 75, 60, 50, 40, 30],
        opacityDown: [0.3, 0.4, 0.5, 0.6, 1, 0.6, 0.5, 0.4, 0.3],
        opacityUp: [0.4, 0.5, 0.6, 1, 0.6, 0.5, 0.4, 0.3],
        heightDown: [30, 45, 60, 75, 100, 75, 60, 45, 30],
        heightUp: [45, 60, 75, 100, 75, 60, 45, 30],
    }
}
const configurationsLandscape = {
    transform: {
        rotateXDown: [60, 50, 40, 30, 0, -30, -40, -50, -60],
        rotateXUp: [50, 40, 30, 0, -30, -40, -50, -60],
        fontSizeDown: [25, 35, 45, 55, 70, 55, 45, 35, 25],
        fontSizeUp: [35, 45, 55, 70, 55, 45, 35, 25],
        opacityDown: [0.3, 0.4, 0.5, 0.6, 1, 0.6, 0.5, 0.4, 0.3],
        opacityUp: [0.4, 0.5, 0.6, 1, 0.6, 0.5, 0.4, 0.3],
        heightDown: [30, 45, 60, 75, 100, 75, 60, 45, 30],
        heightUp: [45, 60, 75, 100, 75, 60, 45, 30],
    }
}
const configurationsPortrait = {
    transform: {
        rotateXDown: [50, 40, 30, 20, 0, -20, -30, -40, -50],
        rotateXUp: [40, 30, 20, 0, -20, -30, -40, -50],
        fontSizeDown: [13, 18, 24, 30, 37, 30, 24, 18, 13],
        fontSizeUp: [18, 24, 30, 37, 30, 24, 18, 13],
        opacityDown: [0.3, 0.4, 0.5, 0.6, 1, 0.6, 0.5, 0.4, 0.3],
        opacityUp: [0.4, 0.5, 0.6, 1, 0.6, 0.5, 0.4, 0.3],
        heightDown: [30, 45, 60, 75, 100, 75, 60, 45, 30],
        heightUp: [45, 60, 75, 100, 75, 60, 45, 30],
    }
}
let isAnimation = false;
let heightSubMenu = null;
let lastHeightSubMenu = null;
let lastActive = null;
let lastActiveItem = null;
let frames = {
    transform: (items, activeItem, direction, isSubMenu) => {
          const configurations = innerWidth > 1023 ? (isRtl ? configurationsLandscapeRtl : configurationsLandscape) : configurationsPortrait;
          items.forEach((item, i) => {
            //ROTATE X
            const currentRotateX = parseInt(item.getAttribute("rotateX")) || 0;
            const oneFrameRotateX = (configurations.transform[direction === "down" ? "rotateXDown" : "rotateXUp"][i] -  currentRotateX) / framesNum;
            const nextFrameRotateX = currentRotateX + oneFrameRotateX * activeFrame;
            item.style.transform = `rotateX(${nextFrameRotateX}deg)`;
            if(activeFrame >= framesNum) {
                item.setAttribute("rotateX", nextFrameRotateX)
            }

            //FONT SIZE
            const currentFontSize = parseInt(item.getAttribute("fontSize")) || 0;
            const oneFrameFontSize = (configurations.transform[direction === "down" ? "fontSizeDown" : "fontSizeUp"][i] -  currentFontSize) / framesNum;
            const nextFrameFontSize = currentFontSize + oneFrameFontSize * activeFrame;
            item.style.fontSize = `${nextFrameFontSize}px`;
            if(activeFrame >= framesNum) {
                item.setAttribute("fontSize", nextFrameFontSize)
            }
            //OPACITY
            const currentOpacity = parseFloat(item.getAttribute("opacity")) || 0;
            const oneFrameOpacity = (configurations.transform[direction === "down" ? "opacityDown" : "opacityUp"][i] -  currentOpacity) / framesNum;
            const nextFrameOpacity = currentOpacity + oneFrameOpacity * activeFrame;
            item.style.opacity = `${nextFrameOpacity}`;
            if(activeFrame >= framesNum) {
                item.setAttribute("opacity", nextFrameOpacity);

            }

            if(configurations.transform[direction === "down" ? "rotateXDown" : "rotateXUp"][i] === 0) {
                if(lastActiveItem) {

                    lastActiveItem.style.padding = innerWidth > 1023 ?
                        `${30 -  15 / framesNum * activeFrame }px 0px ${(lastActive ? 40 : 30) - (lastActive ? 25 : 15) / framesNum * activeFrame}px 0px` :
                        `15px 0px ${lastActive ? (25 - 10/ framesNum * activeFrame ) : 15}px 0px`
                }
                if(lastActive) {
                    lastActive.style.height =`${lastHeightSubMenu - lastHeightSubMenu / framesNum * activeFrame}px`;

                    if(activeFrame >= framesNum) {
                        lastActive.style.position = "absolute";
                        lastActive.style.visibility = "hidden";
                        lastActive.style.height = lastHeightSubMenu + "px";

                    }
                }


                           const subMenu = item.querySelector(".sub-menu");
                item.style.padding = innerWidth > 1023 ?
                    `${15 + 15 / framesNum * activeFrame}px 0px ${15 + (subMenu ? 25 : 15) / framesNum * activeFrame}px 0px`:
                    `15px 0px ${subMenu ? 15 + 10 / framesNum * activeFrame : 15}px 0px`

                if(true) {
                    if(subMenu) {
                        if(activeFrame === 0) {
                            const height = parseFloat(getComputedStyle(subMenu).blockSize);
                            heightSubMenu = height;
                            subMenu.style.height = "0px";
                            subMenu.style.position = "static";
                            subMenu.style.visibility = "visible";

                        }
                        subMenu.style.height =`${heightSubMenu / framesNum * activeFrame}px`;
                    }
                }
                if(activeFrame >= framesNum) {
                    lastActive = subMenu;
                    lastHeightSubMenu = heightSubMenu;
                    lastActiveItem = item;
                }
            }

        })
    },
    height: (first, last) => {
        first.style.height = `${getTempHeightDueOrientation()  / framesNum * activeFrame}px`;
        first.style.padding = `${15 / framesNum * activeFrame}px`;
        last.style.height = `${getTempHeightDueOrientation()  - getTempHeightDueOrientation()  / framesNum * activeFrame}px`;
        last.style.padding = `${15 - 15 / framesNum * activeFrame}px`;
        if(activeFrame >= framesNum) {
            first.style.height = "max-content";
            last.remove();
        }

    }
}

const animation = (items, activeItem, direction, isSubMenu) => {
    if(activeFrame <= framesNum) {
        isAnimation = true;
        requestAnimationFrame(() => {
            frames.transform(items, activeItem, direction, isSubMenu);
            if(direction === "up") {
                frames.height(items[0], items[items.length - 1])
            }
            else {
                 frames.height( items[items.length - 1], items[0])

            }
            activeFrame += 1;
            animation(items, activeItem, direction, isSubMenu);
        })
    }else {
        activeFrame = 0;
        isAnimation = false;


    }
}



export default function showHeader() {

    function fixBtn() {
        const headerSimplexNYC = document.querySelector('.simplexnyc-header');

        if (!headerSimplexNYC) {
            return;
        }

        window.addEventListener('scroll', () => {
            const fixedBtn = headerSimplexNYC.querySelector('.site-header__creating-link');
            const headerHeight = headerSimplexNYC?.offsetHeight;

            if (window.pageYOffset > headerHeight) {
                fixedBtn.classList.add('fixed');
            } else {
                fixedBtn.classList.remove('fixed');
            }
        })
    }

    fixBtn();

    function rechangingElements(direction, navs, container) {
        if(direction === "up") {
            const last = navs[navs.length - 1];
            if(last) {
                const cloneLast = last.cloneNode(true);
                cloneLast.style.transform = `rotateX(${60}deg) translateZ(-60px)`;
                cloneLast.style.height = "0px";
                cloneLast.style.padding = "0px";
                cloneLast.setAttribute("rotateX", 60);
                container.insertBefore(cloneLast, container.firstElementChild);
                last.style.height = getTempHeightDueOrientation() + "px";
                last.style.padding = "15px";
                if (cloneLast.querySelector(".sub-menu")) {
                    cloneLast.addEventListener("click", animationMoveToClick)
                }
            }
        }else if(direction === "down") {
            const first = navs[0];
            if(first) {
                const cloneFirst = first.cloneNode(true);
                cloneFirst.style.transform = `rotateX(${-60}deg) translateZ(-60px)`;
                cloneFirst.setAttribute("rotateX", -60);
                container.appendChild(cloneFirst);
                first.style.height = getTempHeightDueOrientation() + "px";
                first.style.padding = "0px";
                if(cloneFirst.querySelector(".sub-menu")) {
                    cloneFirst.addEventListener("click", animationMoveToClick)
                }
            }
        }
    }
    // eslint-disable-next-line import/prefer-default-export
    /* eslint-disable */

    const nav = document.querySelector('.site-header__menu-wrapper');
    const headerWrapper = document.querySelector('.site-header__header-wrapper');
    const burger = document.querySelector('.js-header-burger');
    const navsContainer = document.querySelector(".site-header__menu");

    const main = document.querySelector("main");
    let navs = document.querySelectorAll(".site-header__menu > li");
    navs.forEach(nav => {
        if(nav.querySelector(".sub-menu")) {
            nav.addEventListener("click", animationMoveToClick)
        }
    });
    async function animationMoveToClick(e) {
        const navsContainer = document.querySelector(".site-header__menu");
        let navs = document.querySelectorAll(".site-header__menu > li");
        let targetIndex = [...navs].indexOf(e.currentTarget);
        if(targetIndex > 3 ) {
                let diff =  Math.abs(targetIndex - 3)
                let arr = Object.keys(Array(diff).fill(1));
                for(const i of arr) {
                    console.log(Number(i) + 1,  Number(i) + 1 < arr.length, arr.length)
                    await scrollHandlerDecorator({deltaY: 1, preventDefault: () => {}}, null,  Number(i) + 1 < arr.length ?  false : true);

                }
        }
         if(targetIndex < 3 ) {
            let diff =  Math.abs(3 - targetIndex)
            let arr = Object.keys(Array(diff).fill(1));
            for(const i of arr) {
                await scrollHandlerDecorator({deltaY: -1, preventDefault: () => {}}, null,  Number(i) + 1 < arr.length ?  false : true);

            }
        }
    }
    const [upArrow, downArrow] = document.querySelectorAll(".menu-arrows img")
    let isFirst = true;

    let number = 0;
    let menuOpener = true;
    let prevDirection = "down";
    let initialY = null;
    let lastY = null;

    const activeItemMenu = document.querySelector("header .current-menu-ancestor");
    const currentMenuItem =  document.querySelector("header .current-menu-item");
    function moveActive() {
        const navs = document.querySelectorAll(".site-header__menu > li");
        let index = null;
        if(activeItemMenu) {
             index = [...navs].findIndex(item => item.classList.contains("current-menu-ancestor"));
        }
        else if(currentMenuItem) {
            index = [...navs].findIndex(item => item.classList.contains("current-menu-item"));
        }
        if(index || index === 0) {
            if(index === 4) {
                return
            }
            if(index  < 4) {
                const dif = 4 - index;
                const elements = [...navs].slice(-dif);

                navsContainer.prepend(...elements)
            }
            if(index > 4) {
                const dif = index - 4;
                const elements = [...navs].slice(0, dif);
                navsContainer.append(...elements)
            }


        }

    }
    if(activeItemMenu || currentMenuItem) {
        moveActive()
    }
    const menuOpen = () => {
        if((window.location.pathname === "/" || window.location.pathname === "/simplex/")) {
            stopLotties();
        }
        nav.style.animation = "openMenu .7s ease-out forwards";
        main.style.transition = "all .5s linear";
        main.style.transform = `translate(0px, ${innerHeight}px)`;
        burger.classList.add('active');
        headerWrapper.classList.add('menu-open');
        window.addEventListener("wheel", scrollHandlerDecorator, {passive: false});
        document.addEventListener('touchstart', touchStartHandler, {passive: false});
        document.addEventListener('touchmove', touchMoveHandler, {passive: false});
        document.addEventListener('touchend', touchEndHandler, {passive: false});
        upArrow.addEventListener("click", upArrowHandler)
        downArrow.addEventListener("click", downArrowHandler)
        //document.body.style.overflowY = "hidden";
    };

    const menuClose = () => {
        /*nav.classList.remove('open'); */
        nav.style.animation = "closeMenu .7s ease-out forwards";
          main.style.transform = "translate(0px, 0px)";
        if(window.location.pathname === "/" || window.location.pathname === "/simplex/") {
            resumeLottie()
        }
        burger.classList.remove('active');
        window.removeEventListener("wheel", scrollHandlerDecorator);
        headerWrapper.classList.remove('menu-open');
        document.removeEventListener('touchstart', touchStartHandler);
        document.removeEventListener('touchmove', touchMoveHandler);
        document.removeEventListener('touchend', touchEndHandler);
        upArrow.removeEventListener("click", upArrowHandler)
        downArrow.removeEventListener("click", downArrowHandler);
       // document.body.style.overflowY = "auto";
    };

    burger && burger.addEventListener('click', function () {
        if (menuOpener) {
            menuOpen();
        } else {
            menuClose();
        }
        menuOpener = !menuOpener;
    });

    function touchStartHandler(e) {
        initialY = e.touches[0].clientY;
        lastY = initialY;
    }
    function touchMoveHandler(e) {
        e.preventDefault();
        if (initialY === null) return;

        let currentY = e.touches[0].clientY;
        let diffY = lastY - currentY;

        if (Math.abs(diffY) >= 50) {
            if (diffY > 0) {
                scrollHandlerDecorator({preventDefault: e.preventDefault.bind(e)}, "down")
            } else {
                // swiped down
                scrollHandlerDecorator({preventDefault: e.preventDefault.bind(e)}, "up")
            }
            lastY = currentY;
        }
    }
    function upArrowHandler(e) {
        scrollHandlerDecorator({preventDefault: e.preventDefault.bind(e)}, "up")

    }
    function downArrowHandler(e) {
        scrollHandlerDecorator({preventDefault: e.preventDefault.bind(e)}, "down")

    }
    function touchEndHandler(e) {
        initialY = null;
        lastY = null;
    }
    function scrollHandlerDecorator(e, dir, isSubMenu = true) {
        e.preventDefault();
        if(isAnimation) return
        const direction = dir ||( e.deltaY >= 0 ? "down" : "up");
        let navs = document.querySelectorAll(".site-header__menu > li");

        rechangingElements(direction, navs, navsContainer);
        navs = document.querySelectorAll(".site-header__menu > li");
        if(direction === "up") {
             animation([...navs], 3, direction, isSubMenu)
        }
        else {
            animation([...navs], 4, direction, isSubMenu)

        }
        return new Promise((res, rej) => {
            const interval = setInterval(() => {
                if(!isAnimation) {
                    clearInterval(interval);
                    res(true)
                }
            }, 100)
        })

    }


    scrollHandlerDecorator({deltaY: 1, preventDefault: () => {}})
 }
