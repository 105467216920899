// eslint-disable-next-line import/prefer-default-export
/* eslint-disable */
import {throttle} from "./simple";
function createTitleRow(text, container) {
    const row = document.createElement("span")
    row.innerHTML = text;
    row.classList.add("animated-on-scroll");
    row.style.transform = `rotateY(${-60}deg)`;
    /*const gradient = `linear-gradient(to right, rgba(0, 0, 0, 0) ${100}%,  rgba(0, 0, 0, ${1}) ${100}%)`;
    row.style.background = gradient;*/
    container.appendChild(row)

}
function createTitleRowForAuto(text, container) {
    const row = document.createElement("span")
    row.innerHTML = text;
    row.classList.add("animated");
    row.style.transform = `rotateY(${60}deg)`;
    /*const gradient = `linear-gradient(to right, rgba(0, 0, 0, 0) ${100}%,  rgba(0, 0, 0, ${1}) ${100}%)`;
    row.style.background = gradient;*/
    container.appendChild(row)

}
function getRGBValues(el) {
    const rgbValue = getComputedStyle(el).color
    const matches = rgbValue.match(/\d+/g); // Извлекаем числа из строки
    if (matches) {
        const red = parseInt(matches[0], 10);
        const green = parseInt(matches[1], 10);
        const blue = parseInt(matches[2], 10);
        return [red, green, blue]
    }
}
export function addTitleEffect(
    container,
    {distance = window.innerHeight, speed = 0.09} = {}
) {
    const rows = container.innerHTML.trim().split("\n").map(el => el.trim())
    container.innerHTML = "";
    container.style.display = "flex";
    container.style.flexDirection = "column";
    container.style.perspective = "150vw";

    rows.forEach(row => createTitleRow(row, container))

    const elements = container.querySelectorAll('*');

    const {top: offsetTop} = container.getBoundingClientRect();
    const {scrollY} = window;
    const offsetDocument = offsetTop + scrollY;
    let isFirst = null;
    const listener = (el, i, maxRotate) => {
        const {top: offsetTop, height} = el.getBoundingClientRect();
        const {scrollY} = window;
        const offsetDocument = offsetTop + scrollY;
        let isOut = false;
        const baseColor = getRGBValues(el);

        return () => {
            requestAnimationFrame(() => {
                if (
                    offsetDocument  < window.scrollY + window.innerHeight &&
                    offsetDocument + distance > window.scrollY + window.innerHeight  - 200
                ) {
                    isOut = false
                    const g = (window.innerHeight + window.scrollY - offsetDocument ) * (speed);
                    // eslint-disable-next
                    let value = (window.innerHeight + window.scrollY - offsetDocument ) * (speed * 1.3) - 70;
                    // eslint-disable-next-line no-param-reassign
                    el.style.transform = `rotateY(${value}deg)`;

                    /*const gradient = `linear-gradient(to right, rgba(0, 0, 0, 0) ${100 - g * 3}%,  rgba(${baseColor[0]}, ${baseColor[1]}, ${baseColor[2]}, ${1 - (100 - g * 1.5) / 300}) ${100 - g * 1.5}%)`;
                    el.style.background = gradient;
                    el.style['-webkit-background-clip'] = 'text';*/

                }
           /*     else if(offsetDocument + i * height + 50 < window.scrollY + window.innerHeight && !isOut) {
                    console.log("OUT")
                    isOut = true;
                    const gradient = `linear-gradient(to right, rgba(0, 0, 0, 0) ${100}%,  rgba(31, 61, 77, ${1}) ${0}%)`;
                    el.style.background = gradient;
                    el.style['-webkit-background-clip'] = 'text';
                }*/
                else if( offsetDocument + distance > window.scrollY + window.innerHeight && !isOut) {
                    isOut = true
                    /*console.log("OUT")


                    const gradient = `linear-gradient(to right, rgba(0, 0, 0, 0) ${100}%,  rgba(31, 61, 77, ${1}) ${100}%)`;
                    el.style.background = gradient;
                    el.style['-webkit-background-clip'] = 'text';*/
                }
            })

        }

    };
   /* const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting && entry.rootBounds.top < innerHeight / 2) {
                isFirst = true
                console.log("INTERSECTIONS")
                const gradient = `linear-gradient(to right, rgba(0, 0, 0, 0) ${50}%,  rgba(31, 61, 77, ${1}) ${50}%)`;
                entry.target.style.background = gradient;
                entry.target.style['-webkit-background-clip'] = 'text';
            }
        });
    });
    observer.observe(container);*/
    elements.forEach((el, i) => {
        el.style.transform = `rotateY(${-70}deg)`;
        /*const gradient = `linear-gradient(to right, rgba(0, 0, 0, 0) ${100}%,  rgba(0, 0, 0, ${1}) ${100}%)`;
        el.style.background = gradient;*/


        listener(el, i)()
        document.addEventListener('scroll', throttle(listener(el, i, elements.length * 15  - (10 * (i + 1))), 50));
    })

}


export function addTitlesEffect() {
    const titles = document.querySelectorAll('.title-rotate-effect-container');
    titles.forEach((titleContainer) => {
        addTitleEffect(titleContainer)
    })
}

function autoAnimateRow(el) {
    let angle = 60;
    let colorV = 100;
    let opacityV = 100
    const baseColor = getRGBValues(el);

    function animate() {
        el.style.transform = `rotateY(${angle}deg)`;
        const gradient = `linear-gradient(to left, rgba(0, 0, 0, 0) ${colorV}%,  rgb(${baseColor[0]}, ${baseColor[1]}, ${baseColor[2]}) ${(opacityV )}%)`;
        el.style.background = gradient;
        el.style['-webkit-background-clip'] = 'text';

        angle -= 1.5
        colorV -= 3.3333
        opacityV -= 2.5;
        if(angle <= 0 ) return
        requestAnimationFrame(animate)
    }
    animate()
}

function addAutoTitleEffect(container, {speed = 0.13} = {}) {
    const rows = container.innerHTML.trim().split("\n").map(el => el.trim())
    container.innerHTML = "";
    container.style.display = "flex";
    container.style.flexDirection = "column";
    container.style.zIndex = "100";
    rows.forEach(row => createTitleRowForAuto(row, container))
    const elements = container.querySelectorAll('*');


    elements.forEach((el, i) => {
        setTimeout(() => {
            autoAnimateRow(el)
        }, i * 500)
    })

}



export function addAutoTitlesEffect() {
    const titles = document.querySelectorAll('.title-auto-rotate-effect-container');
    titles.forEach((titleContainer) => {
        addAutoTitleEffect(titleContainer)
    })
}
