function updateCart() {
    console.log('cart updated');
    const updateCartBtn = document.querySelector('[name=update_cart]');
    updateCartBtn.disabled = false;
    updateCartBtn.click();

    // jQuery(document.body).trigger('added_to_cart');
    // jQuery(document.body).trigger('updated_wc_div');
}

function additionAddToCart(e) {
    const ajaxUrl = window.var_from_php.ajax_url;
    const productId = e.target.getAttribute('data-product');
    console.log(`adding to cart: ${productId}`);
    const formData = {
        action: 'addition_add_to_cart',
        product_id: productId,
    };

    jQuery.ajax({
        url: ajaxUrl,
        data: formData,
        type: 'POST',
        // eslint-disable-next-line
        beforeSend() {},
        success(response) {
            // console.log(response);
            updateCart();
        },
        error(xhr, ajaxOptions, thrownError) {
            console.log(xhr.status);
            console.log(thrownError);
        },
    });

    return false;
}

function additionRemoveFromCart(e) {
    const ajaxUrl = window.var_from_php.ajax_url;
    const productId = e.target.getAttribute('data-product');
    console.log(`removing from cart: ${productId}`);
    const formData = {
        action: 'addition_remove_from_cart',
        product_id: productId,
    };

    jQuery.ajax({
        url: ajaxUrl,
        data: formData,
        type: 'POST',
        // eslint-disable-next-line
        beforeSend() {},
        success(response) {
            // console.log(response);
            updateCart();
        },
        error(xhr, ajaxOptions, thrownError) {
            console.log(xhr.status);
            console.log(thrownError);
        },
    });

    return false;
}

function additionChangeQuantity(e) {
    const ajaxUrl = window.var_from_php.ajax_url;
    const productId = e.target.getAttribute('data-product');
    console.log(`changed input: ${productId}`);
    const formData = {
        action: 'addition_change_count',
        product_id: productId,
        quantity: e.target.value,
    };

    jQuery.ajax({
        url: ajaxUrl,
        data: formData,
        type: 'POST',
        // eslint-disable-next-line
        beforeSend() {},
        success(response) {
            // console.log(response);
            updateCart();
        },
        error(xhr, ajaxOptions, thrownError) {
            console.log(xhr.status);
            console.log(thrownError);
        },
    });

    return false;
}

function quantityPlusMinus(e) {
    const $this = e.target;
    const action = $this.getAttribute('data-action');
    const $quantityToggle = $this.closest('.counter-area');
    const $input = $quantityToggle.querySelector('[type=number]');

    let value = Number($input.value);
    if (action === 'plus') {
        value++;
    } else if (action === 'minus') {
        value--;
    }

    if (value < 0) return;

    $input.value = value;
    $input.dispatchEvent(new Event('change'));
}

function selectedCities(e) {
    const confirmCites = document.getElementById('confirm_cities');
    const proceedToPaymentBtn = document.getElementById(
        'woocommerce_checkout_place_order'
    );

    proceedToPaymentBtn.disabled = !confirmCites.value;
}

function initPreloader() {
    jQuery(document).bind({
        ajaxStart() {
            document.body.classList.add('loading');
            jQuery.blockUI({
                css: { border: 'none' },
                overlayCSS: { backgroundColor: 'transparent', cursor: 'wait' },
                message: '<div class="preloader"></div>',
            });
        },
        ajaxStop() {
            document.body.classList.remove('loading');
            jQuery.unblockUI();
        },
    });
}

function setEvents() {
    const addToCartBtn = document.querySelector('.add-to-cart-button');
    if (addToCartBtn) addToCartBtn.addEventListener('click', additionAddToCart);

    const removeFromCartBtn = document.querySelector(
        '.remove-from-cart-button'
    );
    if (removeFromCartBtn)
        removeFromCartBtn.addEventListener('click', additionRemoveFromCart, {
            once: true,
        });

    const qtyInput = document.querySelector('.counter-area__input');
    if (qtyInput)
        qtyInput.addEventListener('change', additionChangeQuantity, {
            once: true,
        });

    const qtyBtns = document.querySelectorAll('.quantity-toggle-button');
    if (qtyBtns)
        qtyBtns.forEach((button) => {
            button.addEventListener('click', quantityPlusMinus, { once: true });
        });

    const citiesBtns = document.querySelectorAll('.cart-cities__city');
    if (citiesBtns)
        citiesBtns.forEach((button) => {
            button.addEventListener('click', updateCart, { once: true });
        });
}
/* eslint-disable */
export function initCartItemActions() {
    initPreloader();
    setEvents();
    jQuery(document).on('ajaxComplete', setEvents);
    jQuery(document).on('updated_wc_div', selectedCities);
}
