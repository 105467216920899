var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import getUserLocation from './location';
function addHyphensBetweenWords(inputString) {
    const words = inputString.match(/\w+/g);
    if (words && words.length > 1) {
        return words.join('-');
    }
    return inputString;
}
const switchTab = (oldTab, newTab) => {
    if (oldTab) {
        const oldPanelId = oldTab.getAttribute('href').substring(1);
        const oldPanel = document.getElementById(oldPanelId);
        oldTab.removeAttribute('aria-selected');
        oldPanel.classList.add('hidden');
        oldTab.setAttribute('tabindex', '-1');
    }
    const newPanelId = newTab.getAttribute('href').substring(1);
    const newPanel = document.getElementById(newPanelId);
    newPanel.classList.remove('hidden');
    newTab.focus();
    newTab.removeAttribute('tabindex');
    newTab.setAttribute('aria-selected', 'true');
};
function tabs() {
    return __awaiter(this, void 0, void 0, function* () {
        const tabLists = document.querySelectorAll("ul[role='tablist']");
        const panels = document.querySelectorAll("section[role='tabpanel']");
        const result = yield getUserLocation();
        const noCountryMessage = document.getElementById('no-location');
        const tabsWrapper = document.getElementById('pricing-tabs-wrapper');
        if (result) {
            const { country_name } = result;
            const countryModified = country_name.trim().toLowerCase();
            let countryToMatch = addHyphensBetweenWords(countryModified);
            if (countryToMatch === 'united-states') {
                countryToMatch = 'new-york';
            }
            const targetTab = document.querySelector(`[data-country="${countryToMatch}"]`);
            if (targetTab) {
                targetTab.setAttribute('aria-selected', 'true');
            }
            switchTab(tabLists[0].querySelector('a'), targetTab);
        }
        const LEFT_ARROW = 37;
        const RIGHT_ARROW = 39;
        const DOWN_ARROW = 40;
        tabLists.forEach(function (tablist) {
            const tabs = Array.from(tablist.querySelectorAll('a'));
            tabs.forEach(function (tab, i) {
                const panelId = tab.getAttribute('href').substring(1);
                const panel = document.getElementById(panelId);
                tab.addEventListener('click', (e) => {
                    e.preventDefault();
                    if (tabsWrapper && tabsWrapper.classList.contains('disabled')) {
                        tabsWrapper.classList.remove('disabled');
                    }
                    if (noCountryMessage && noCountryMessage.classList.contains('active')) {
                        noCountryMessage.classList.remove('active');
                    }
                    tablist.classList.remove('country-not-matched');
                    const currentTab = tablist.querySelector('[aria-selected]') || tabLists[0].querySelector('a');
                    if (currentTab) {
                        currentTab.setAttribute('aria-selected', 'true');
                        const tabsClass = currentTab.getAttribute('href');
                        let currentTabClicked;
                        if (tabsClass) {
                            const tabId = tabsClass.replace('#', '');
                            currentTabClicked = document.getElementById(tabId);
                        }
                        if (e.currentTarget !== currentTab) {
                            switchTab(currentTab, e.currentTarget);
                        }
                        else {
                            currentTabClicked;
                        }
                    }
                });
                tab.addEventListener('keydown', (e) => {
                    const index = tabs.indexOf(e.currentTarget);
                    if (e.keyCode === DOWN_ARROW) {
                        e.preventDefault();
                        panel.focus();
                    }
                    if (e.keyCode === LEFT_ARROW) {
                        e.preventDefault();
                        if (tabs[index - 1]) {
                            switchTab(e.currentTarget, tabs[index - 1]);
                        }
                    }
                    if (e.keyCode === RIGHT_ARROW) {
                        e.preventDefault();
                        if (tabs[index + 1]) {
                            switchTab(e.currentTarget, tabs[index + 1]);
                        }
                    }
                });
            });
        });
        const packageBoxes = document.querySelectorAll('.package');
        packageBoxes.forEach((packageBox) => {
            const expanderShow = packageBox.querySelector('.expander-show');
            const expanderLess = packageBox.querySelector('.expander-less');
            expanderShow &&
                expanderShow.addEventListener('click', function (e) {
                    e.preventDefault();
                    packageBox.classList.add('active');
                });
            expanderLess &&
                expanderLess.addEventListener('click', function (e) {
                    e.preventDefault();
                    packageBox.classList.remove('active');
                });
        });
    });
}
export default tabs;
