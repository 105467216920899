/* eslint-disable */

import {throttle} from "./simple";

let current = 0;
let direction = "up";
let distance = innerHeight / 100 * 3
let availableFigures = [];
let step = distance / 100
function moveGroupFigures() {
    availableFigures.forEach((figure) => {
        let transform = window.getComputedStyle(figure).transform;
        let scale = /matrix\(([^)]+)\)/.exec(transform)[1].split(',').map(parseFloat)[0]
        figure.style.transform = `scale(${scale < 1 ? scale + .03 : 1}) translateY(${current}px)`;
    })
    if(direction === "up" && current + step <= distance) {
        current += step;
    }
    else if(direction === "up" && current + step > distance) {
        direction = "down"
        current = distance;
    }
    else if(direction === "down"  && current - step >= 0) {
        current -= step;
    }
    else if(direction === "down"  && current - step < 0) {
        direction = "up";
        current = 0;
    }
    requestAnimationFrame(moveGroupFigures)
}

export function addToFigureParallax(figure, i) {
    let isFirst = true;
    function listener() {
        if(figure.getBoundingClientRect().top < innerHeight * .9 && figure.getBoundingClientRect().top > 0  && isFirst) {
            figure.style.transform = "scale(1)";
            setTimeout(() => {
                figure.style.animation = `figure-parallax-effect ${(i % 4) + 3}s ease-in-out infinite`;
            },1300 + Math.round(Math.random() * 1000)   );
        }
    }
    listener();
    document.addEventListener("scroll", listener)

}
function preSettingImage(figure) {

    figure.style.transition = `none`;
    figure.style.transform = `scale(0) translateY(${current}px)`;
    availableFigures.push(figure)
}
export function addToFigureParallaxGroup(figure, i) {
    let isFirst = true; 
    function listener() { 
        if(figure.getBoundingClientRect().top < innerHeight * .9 && figure.getBoundingClientRect().top > 0  && isFirst) {
            if (figure.tagName === 'IMG') {
                if(figure.complete) {
                    preSettingImage(figure);
                    isFirst = false;
                }
                else {
                   figure.addEventListener('load', function() {
                        preSettingImage(figure);
                        isFirst = false;
                    }) 
                }
                
            }else {
                  preSettingImage(figure);
                  isFirst = false;
            }
           
        }
    }
    listener();
    document.addEventListener("scroll", listener)
}
export function addFiguresParallax() {
    const figures = document.querySelectorAll('.figure-parallax-effect');
    figures.forEach((figure, i) => {
        setTimeout( function() {
            addToFigureParallax(figure, i);
        }, 100 );
    });


    const figureGroups = document.querySelectorAll('.figure-parallax-effect-group');
    requestAnimationFrame(moveGroupFigures)
    figureGroups.forEach((figure, i) => {
        addToFigureParallaxGroup(figure, i);

    });
}
