import smoothscroll from 'smoothscroll-polyfill';
import lottie from 'lottie-web';

// kick off the polyfill!
smoothscroll.polyfill();

/**
 * Fade Out method
 *
 * @param {string} el
 */
export function fadeOut(el) {
    if (!el) {
        throw Error('"fadeOut function - "You didn\'t add required parameters');
    }

    const domElement = document.querySelector(el);

    if (!domElement) {
        throw Error("domElement doesn't exist");
    }

    domElement.style.opacity = 1;

    (function fade() {
        if ((domElement.style.opacity -= 0.1) < 0) {
            domElement.style.display = 'none';
        } else {
            requestAnimationFrame(fade);
        }
    })();
}

/**
 * Fade In method
 *
 * @param {string} el      - element that need to fade in
 *
 * @param {string} display - display variant
 */
export function fadeIn(el, display = 'block') {
    if (!el) {
        throw Error('"fadeIn function - "You didn\'t add required parameters');
    }

    const domElement = document.querySelector(el);

    if (!domElement) {
        throw Error("domElement doesn't exist");
    }

    domElement.style.opacity = 0;
    domElement.style.display = display || 'block';

    (function fade() {
        let val = parseFloat(domElement.style.opacity);
        if (!((val += 0.1) > 1)) {
            domElement.style.opacity = val;
            requestAnimationFrame(fade);
        }
    })();
}

/**
 *  Set equal height to selected elements calculated as bigger height
 *
 * @param {Array | string} elementsSelector  - selector for searching elements
 * @param {string} minOrMax          - Define what dimension should be calculated (minHeight or maxHeight)
 * @return {Array | string} elementsSelector
 */
export function equalHeights(elementsSelector, minOrMax = 'max') {
    if (!elementsSelector) {
        throw Error(
            '"equalHeights function - "You didn\'t add required parameters'
        );
    }

    const heights = [];
    const elementsSelectorArr = Array.isArray(elementsSelector)
        ? elementsSelector
        : [...document.querySelectorAll(elementsSelector)];

    elementsSelectorArr.forEach((item) => {
        // eslint-disable-next-line no-param-reassign
        item.style.height = 'auto';
    });

    elementsSelectorArr.forEach((item) => {
        heights.push(item.offsetHeight);
    });

    const commonHeight =
        minOrMax === 'max'
            ? Math.max.apply(0, heights)
            : Math.min.apply(0, heights);

    elementsSelectorArr.forEach((item) => {
        // eslint-disable-next-line no-param-reassign
        item.style.height = `${commonHeight}px`;
    });

    return elementsSelector;
}

/**
 * Trim all paragraph from unneeded space symbol
 */
export function trimParagraph() {
    [...document.querySelectorAll('p')].forEach((item) => {
        // eslint-disable-next-line no-param-reassign
        item.innerHTML = item.innerHTML.trim();
    });
}

/**
 * Check if element in viewport
 *
 * @param {HTMLElement | null} el
 * @param {number} offset - Adjustable offset value when element becomes visible
 * @return {boolean} Result of checking
 */
export function isInViewport(el, offset = 100) {
    if (!el) {
        throw Error(
            '"isInViewport function - "You didn\'t add required parameters'
        );
    }

    const scroll = window.scrollY || window.pageYOffset;
    const boundsTop = el.getBoundingClientRect().top + offset + scroll;

    const viewport = {
        top: scroll,
        bottom: scroll + window.innerHeight,
    };

    const bounds = {
        top: boundsTop,
        bottom: boundsTop + el.clientHeight,
    };

    return (
        (bounds.bottom >= viewport.top && bounds.bottom <= viewport.bottom) ||
        (bounds.top <= viewport.bottom && bounds.top >= viewport.top)
    );
}

/**
 * Debounce function
 *
 * @param {Function | null} fn  - function that should be executed
 * @param {number} time         - time delay
 * @return {Function}           - function to execute
 */
export const debounce = (fn, time = 1000) => {
    if (!fn) {
        throw Error(
            '"debounce function - "You didn\'t add required parameters'
        );
    }

    let timeout;

    return () => {
        // eslint-disable-next-line no-undef
        const functionCall = () => fn.apply(this, arguments);

        clearTimeout(timeout);
        timeout = setTimeout(functionCall, time);
    };
};

/**
 * Copy to clipboard
 *
 * @param {HTMLElement | null} parent
 * @param {HTMLElement | null} element -  element that  contain value to copy
 */
export const copyToClipboard = (parent, element) => {
    if (!parent || !element) {
        throw Error(
            '"copyToClipboard function - "You didn\'t add required parameters'
        );
    }

    const el = document.createElement('textarea');
    el.value = element.value;
    document.body.appendChild(el);
    el.select();

    try {
        const successful = document.execCommand('copy');

        if (successful) {
            parent.classList.add('copied');

            setTimeout(() => {
                parent.classList.remove('copied');
            }, 3000);
        }
    } catch (err) {
        // eslint-disable-next-line no-console
        console.log('Oops, unable to copy');
    }

    document.body.removeChild(el);
};

/**
 * Test value with regex
 *
 * @param {string} fieldType  - The allowed type of the fields
 * @param {string} value
 * @return {boolean} Result of checking
 */
export const validateField = (fieldType = null, value = null) => {
    if (!fieldType || !value) {
        throw Error(
            '"validateField function - "You didn\'t add required parameters'
        );
    }

    const phoneREGEX = /^[0-9+]{6,13}$/;
    const nameREGEX = /^[a-zA-Zа-яА-Я\s]{2,30}$/;
    const postalREGEX = /^[A-Z]{1,2}[0-9]{1,2} ?[0-9][A-Z]{2}$/i;
    const emailREGEX = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    const dummyREGEX = /^.+$/;

    let checkResult = false;

    switch (fieldType) {
        case 'name':
            checkResult = nameREGEX.test(value);
            break;
        case 'phone':
            checkResult = phoneREGEX.test(value);
            break;
        case 'postal':
            checkResult = postalREGEX.test(value);
            break;
        case 'email':
            checkResult = emailREGEX.test(value);
            break;
        case 'price':
            checkResult = dummyREGEX.test(value);
            break;
        case 'aim':
            checkResult = dummyREGEX.test(value);
            break;
        case 'date':
            checkResult = dummyREGEX.test(value);
            break;
        case 'subject':
            checkResult = dummyREGEX.test(value);
            break;
        default:
            break;
    }

    return checkResult;
};

/**
 * Polyfill for closest method
 */
export function closestPolyfill() {
    if (window.Element && !Element.prototype.closest) {
        Element.prototype.closest = (s) => {
            const matches = (
                this.document || this.ownerDocument
            ).querySelectorAll(s);
            let i;
            // eslint-disable-next-line @typescript-eslint/no-this-alias
            let el = this;
            do {
                i = matches.length;
                // eslint-disable-next-line no-empty
                while (--i >= 0 && matches.item(i) !== el) {}
            } while (i < 0 && (el = el.parentElement));
            return el;
        };
    }
}

/**
 * Smooth scroll to element on page
 *
 * @param {string|null} elementsSelector string -- css selector (anchor links)
 * @param {Function|null} callback function     -- callback for some additional actions
 */
export function anchorLinkScroll(elementsSelector = null, callback = null) {
    if (!elementsSelector) {
        throw Error(
            '"anchorLinkScroll function - "You didn\'t add correct selector for anchor links'
        );
    }

    const elements = document.querySelectorAll(elementsSelector);

    elements &&
        [...elements].forEach((link) => {
            link.addEventListener('click', (event) => {
                event.preventDefault();

                const elHref =
                    event.target.nodeName === 'A'
                        ? event.target.getAttribute('href')
                        : event.target.dataset.href;

                const ANCHOR_ELEMENT = document.querySelector(elHref);

                ANCHOR_ELEMENT &&
                    window.scroll({
                        behavior: 'smooth',
                        left: 0,
                        top: ANCHOR_ELEMENT.offsetTop,
                    });

                if (callback) callback();
            });
        });
}

/**
 * Lottie json animation
 *
 * @param {string|null} elementsSelector string -- css selector (animation icon)
 * */

export function lottieIconAnimation(elementsSelector = null) {
    const boxAnimateList = document.querySelectorAll(elementsSelector);
    const boxAnimateArray = [...boxAnimateList];
    const animationsIcon = [];

    boxAnimateArray.forEach((box) => {
        const animation = lottie.loadAnimation({
            container: box,
            path: box.dataset.url,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            resizeMode: 'center',
        });

        animationsIcon.push(animation);
    });
}

/**
 * Scroll section animation
 * */

export function scrollSectionAnimation() {
    const scrolledSection = document.querySelectorAll('.scrolled-section');
    const scrollDetection = document.querySelectorAll('.scroll-detection');
    const buttonToggle = document.querySelector('.btn-toggle');

    if (window.innerWidth > 1024) {
        // eslint-disable-next-line
        function selectNear() {
            let min = -999999;
            let active = null;
            scrollDetection.forEach((section, index) => {
                const { top } = section.getBoundingClientRect();
                if (top <= 0 && top > min) {
                    min = top;
                    active = scrolledSection[index];
                }
            });
            if (!active) {
                return scrolledSection[0];
            }
            return active;
        }
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(() => {
                const nearElement = selectNear();
                if (buttonToggle) {
                    if (nearElement.classList.contains('section-1')) {
                        buttonToggle.classList.remove('active');
                    } else {
                        buttonToggle.classList.add('active');
                    }
                }
                scrolledSection.forEach((section) => {
                    section.classList.remove('active');
                });
                nearElement.classList.add('active');
            });
        });

        scrollDetection.forEach((section) => {
            observer.observe(section);
        });
    }
}

/**
 * Check MAC OS
 * */

export function addBodyClass() {
    const isMacOS = navigator.userAgent.indexOf('Mac OS X') !== -1;
    const bodyElement = document.body;

    if (isMacOS) {
        bodyElement.classList.add('mac');
    }
}

/**
 * Accept cookies on submit Book a Demo form
 * */

export function acceptCookies() {
    // eslint-disable-next-line
    const bookDemoForm = document.querySelector('.book-a-demo-hero-form .wpcf7-form');
    // eslint-disable-next-line
    const selectSubject = document.querySelector('.custom-select-form-element');
    if (bookDemoForm) {
        const id = bookDemoForm.getAttribute('id');
        // eslint-disable-next-line @wordpress/no-global-event-listener
        document.addEventListener(
            'wpcf7invalid',
            // eslint-disable-next-line func-names
            function (event) {
                const rockSubject = event.detail.inputs;
                rockSubject.forEach((item) => {
                    const { name, value } = item;

                    if (name === 'rock-subject' && value === '') {
                        selectSubject.classList.add('not-valid');
                    } else {
                        selectSubject.classList.remove('not-valid');
                    }
                });
            },
            false
        );

        document.addEventListener(
            'wpcf7mailsent',
            // eslint-disable-next-line func-names
            function (event) {
                if (id === event.detail.contactFormId) {
                    document.querySelector('.cky-btn-accept').click();
                }
            },
            false
        );
    }
}

/* global wc_checkout_params */
export function validateCheckoutFields() {
    /* eslint-disable */
    // Find the checkout form element
    window.secondStep = 0;
    const checkout_form = jQuery( 'form.checkout' );
    checkout_form.on( 'checkout_place_order', function() {
        // do your custom stuff
        if(!window.secondStep) {
            checkout_form.append('<input type="hidden" name="m_prevent_submit" value="1">');
        }
    });

    jQuery( document.body ).on( 'checkout_error', function() {
        console.log( 'clcik');
        const error_text = jQuery('.woocommerce-error').find('li').first().text();
        const error_text_block = jQuery('.wc-block-components-notice-banner.is-error').find('.wc-block-components-notice-banner__content').first().text();
        const errorLength = jQuery('.woocommerce-error').find('li').length;
        const errorLength_block = jQuery('.wc-block-components-notice-banner.is-error').find('.wc-block-components-notice-banner__content').length;
        let errorMessage = error_text.trim();
        let errorMessage_block = error_text_block.trim();
        if ( (errorLength == 1 && errorMessage == 'custom_notice') || (errorLength_block == 1 && errorMessage_block == 'custom_notice') ) {
            jQuery('.woocommerce-NoticeGroup').addClass('all-fields');
            jQuery('body').addClass('billing-fields-valid');
            jQuery('#order_review #terms').prop('checked', true);
            jQuery(document.body).trigger('updated_wc_div');
            jQuery('input[name="m_prevent_submit"]').remove();
            jQuery('html, body').animate({
                scrollTop: jQuery("#back-to-checkout-links").offset().top - 100
            }, 500);
            window.secondStep = 1;
        }
    });
}

function backbtn() {
    const backButton = jQuery('#back-to-checkout-links');
    backButton.on('click', function () {
        jQuery('body.billing-fields-valid').removeClass('billing-fields-valid');
        jQuery('input[name="m_prevent_submit"]').remove();
        window.secondStep = 0;
    });
}

export function backbtnInit() {
    backbtn();
    jQuery(document).on('ajaxComplete', backbtn);
}

function getUrlParameter(name) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    const results = regex.exec(location.search);
    return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

const utmParameters = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'];

// Save UTM parameters to sessionStorage if they are present in the URL
utmParameters.forEach(function(param) {
    const value = getUrlParameter(param);
    if (value) {
        sessionStorage.setItem(param, value);
    }
});

export function populateUtmFields(form) {
    utmParameters.forEach(function(param) {
        const value = sessionStorage.getItem(param);
        if (value) {
            const hiddenField = form.querySelector('input[name="' + param + '"]');
            if (hiddenField) {
                hiddenField.value = value;
            }
        }
    });
}
export function cfms7() {
    const dateFormField = jQuery('#current-date');
    const d = new Date();
    const strDate = d.getDate() + "/" + (d.getMonth() + 1) + "/" + d.getFullYear();
    const strTime = d.getHours() + ":" + d.getMinutes() + ":" + d.getSeconds();
    const strDateTime = strDate + " " + strTime;

    setTimeout(() => {
        jQuery(dateFormField).val(strDateTime);
    }, 6000); // Set your desired timeout in milliseconds


    jQuery('#phone-number').attr({
        maxlength: 10,
        minlength: 10,
        pattern: "\\d{10}"
    });

    jQuery(document).on('click', '.cf7mls_next', function (event) {

        const $this = jQuery(this);
        const form = $this.parent().closest("form.wpcf7-form");
        const fd = new FormData(form[0]);

        jQuery.ajax({
            url: cf7mls_object.ajax_url + "cf7mls/v1/cf7mls_validation",
            type: "POST",
            crossDomain: true,
            data: fd,
            processData: false,
            contentType: false,
        }).done(function (json) {
            if (!json.success) {
                const optionsContainers = jQuery('.custom-select-wrapper');
                //console.log(optionsContainers);

                optionsContainers.each(function () {
                    const notValid = jQuery(this).find('.hidden-field');
                    const customSelect = jQuery(this).find('.custom-select-form-element');


                    //console.log(customSelect);

                    if (notValid.length && customSelect.length) {
                        setTimeout(() => {
                            if (notValid.hasClass('wpcf7-not-valid')) {
                                customSelect.addClass('wpcf7-not-valid');
                            } else {
                                customSelect.removeClass('wpcf7-not-valid');
                            }
                        }, 1000); // Set your desired timeout in milliseconds
                    }
                });
            }
        });
    });
}
