const customSelects = document.querySelectorAll('.custom-select-wrapper');
function customSelect() {
    customSelects.forEach(function (select, i) {
        const selectTrigger = select.querySelector('.select-trigger');
        const selectedOption = select.querySelector('.selected-option');
        const optionsList = select.querySelector('.options');
        const targetInput = select.querySelector('.hidden-field');
        const customSelectFormElement = select.querySelector('.custom-select-form-element');
        const options = select.querySelectorAll('.options li');
        if (selectTrigger) {
            selectTrigger.addEventListener('click', function () {
                const isActive = selectTrigger.classList.toggle('active');
                optionsList.style.display = isActive ? 'block' : 'none';
                if (!isActive) {
                    closeAllSelects();
                }
            });
        }
        options.forEach(function (option) {
            option.addEventListener('click', function () {
                const value = option.getAttribute('data-value');
                if (selectedOption) {
                    selectedOption.textContent = option.textContent;
                }
                if (optionsList) {
                    optionsList.style.display = 'none';
                }
                if (selectTrigger) {
                    selectTrigger.classList.remove('active');
                    if (customSelectFormElement) {
                        customSelectFormElement.classList.remove('wpcf7-not-valid');
                    }
                }
                if (targetInput instanceof HTMLInputElement && value) {
                    targetInput.value = value;
                }
            });
        });
    });
}
document.addEventListener('click', function (event) {
    if (event && event.target) {
        const targetElement = event.target;
        if (!targetElement.closest('.custom-select')) {
            closeAllSelects();
        }
    }
});
function closeAllSelects() {
    customSelects.forEach(function (select) {
        const selectTrigger = select.querySelector('.select-trigger');
        const optionsList = select.querySelector('.options');
        if (selectTrigger) {
            selectTrigger.classList.remove('active');
        }
        if (optionsList) {
            optionsList.style.display = 'none';
        }
    });
}
export default customSelect;
