export default function headerHandleColor() {
    const sections = document.querySelectorAll('.content-section');
    const header = document.querySelector('.site-header');

    function selectNear() {
        let min = -999999;
        let active = null;
        sections.forEach((el) => {
            const { top } = el.getBoundingClientRect();
            if (top <= 0 && top > min) {
                min = top;
                active = el;
            }
        });
        return {
            addHeaderClass: active.getAttribute('data-color'),
            deleteHeaderClass:
                active.getAttribute('data-color') === 'header-light'
                    ? 'header-dark'
                    : 'header-light',
        };
    }

    const observer = new IntersectionObserver((entries) => {
        entries.forEach(() => {
            const { addHeaderClass, deleteHeaderClass } = selectNear();
            header.classList.add(addHeaderClass);
            header.classList.remove(deleteHeaderClass);
        });
    });

    sections.forEach((section) => {
        observer.observe(section);
    });
}
