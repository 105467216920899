function cityFilter() {
    const cityFilter = document.getElementById('search-city-input');
    const cityFilterValue = document.getElementById('search-city-value');
    const cityListItems = document.querySelectorAll('.cart-cities__list-select-item');
    const blockElements = document.querySelectorAll('.cart-cities__list-block');
    const notFoundDiv = document.querySelector('.cart-cities__not-found');
    const checkboxes = Array.from(document.querySelectorAll('.cart-cities__city'));
    const cleaner = document.querySelector('.cleaner');
    const selectedCities = [];
    if (cityFilter) {
        cityFilter.addEventListener('input', function () {
            const filterText = cityFilter.value.trim().toLowerCase();
            let foundMatch = false;
            cleaner.style.display = cityFilter.value ? 'block' : 'none';
            if (filterText.length >= 1) {
                cityListItems.forEach(function (item) {
                    var _a;
                    const labelElement = item.querySelector('label span');
                    const cityName = ((_a = labelElement === null || labelElement === void 0 ? void 0 : labelElement.textContent) === null || _a === void 0 ? void 0 : _a.toLowerCase()) || '';
                    const index = cityName.indexOf(filterText);
                    const itemAsHTMLElement = item;
                    if (labelElement) {
                        const cityName = item.textContent;
                        const regex = new RegExp(filterText, 'gi');
                        if (cityName) {
                            const highlightedText = cityName.replace(regex, `<span class="highlight-text">$&</span>`);
                            labelElement.innerHTML = highlightedText;
                        }
                    }
                    if (cityName.includes(filterText)) {
                        itemAsHTMLElement.style.display = 'block';
                        foundMatch = true;
                    }
                    else {
                        itemAsHTMLElement.style.display = 'none';
                    }
                });
                blockElements.forEach((blockElement) => {
                    const selectItems = blockElement.querySelectorAll('.cart-cities__list-select-item');
                    const allHidden = Array.from(selectItems).every((item) => item.style.display === 'none');
                    const mainLetterElement = blockElement.querySelector('.cart-cities__list-main-letter');
                    if (mainLetterElement) {
                        mainLetterElement.style.display = allHidden ? 'none' : 'block';
                    }
                });
                if (notFoundDiv) {
                    if (!foundMatch) {
                        notFoundDiv.classList.add('not-relevant');
                    }
                    else {
                        notFoundDiv.classList.remove('not-relevant');
                    }
                }
                cityFilterValue.value = cityFilter.value;
            }
            else {
                cityListItems.forEach(function (item) {
                    const itemAsHTMLElement = item;
                    itemAsHTMLElement.style.display = 'block';
                    foundMatch = true;
                });
                blockElements.forEach((blockElement) => {
                    const mainLetterElement = blockElement.querySelector('.cart-cities__list-main-letter');
                    if (mainLetterElement) {
                        mainLetterElement.style.display = 'block';
                    }
                });
                if (notFoundDiv) {
                    notFoundDiv.classList.remove('not-relevant');
                }
            }
        });
    }
    const cityInPackage = document.getElementById('city-in-package');
    let maxCityCountNumber = 1;
    if (cityInPackage) {
        const maxCityAttributeValue = cityInPackage.getAttribute('data-max-city');
        if (maxCityAttributeValue !== null) {
            maxCityCountNumber = parseInt(maxCityAttributeValue, 10);
        }
        if (maxCityCountNumber === 0) {
            checkboxes.forEach((checkbox) => {
                if (!checkbox.checked) {
                    checkbox.disabled = true;
                }
            });
        }
        else {
            checkboxes.forEach((checkbox) => {
                checkbox.disabled = false;
            });
        }
    }
    if (cleaner) {
        cleaner.addEventListener('click', function (e) {
            cityFilter.value = '';
            cityFilterValue.value = '';
            cityFilter.dispatchEvent(new Event('input'));
        });
    }
}
export default cityFilter;
