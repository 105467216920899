function simpleAccordion(elementClass, eventType) {
    function isMobileDevice() {
        const isMobile = /iPhone|iPad|iPod|Android|webOS|BlackBerry|Windows Phone/i.test(navigator.userAgent);
        return isMobile;
    }
    const items = document.querySelectorAll(`.${elementClass}`);
    const itemAccordion = (item) => {
        const itemToggle = item.getAttribute('aria-expanded');
        const parentElement = item.closest('.accordion-item');
        const clcikParentElement = item.closest('.accordion-item.click-action');
        if (itemToggle === 'true' && !clcikParentElement) {
            return;
        }
        items.forEach((element) => {
            var _a;
            element.setAttribute('aria-expanded', 'false');
            (_a = element.closest('.accordion-item')) === null || _a === void 0 ? void 0 : _a.classList.remove('active');
        });
        if (itemToggle === 'false') {
            item.setAttribute('aria-expanded', 'true');
            parentElement === null || parentElement === void 0 ? void 0 : parentElement.classList.add('active');
        }
    };
    if (eventType == 'click' || isMobileDevice()) {
        items.forEach((item) => {
            item.addEventListener('click', () => itemAccordion(item));
        });
    }
    else {
        items.forEach((item) => {
            const tabItem = item.closest('.tab-container__tab-item');
            if (tabItem) {
                tabItem.addEventListener('mouseenter', () => itemAccordion(item));
                tabItem.addEventListener('mouseleave', () => itemAccordion(item));
            }
        });
    }
}
export default simpleAccordion;
