function addHyphensBetweenWords(inputString) {
    const words = inputString.match(/\w+/g);
    if (words && words.length > 1) {
        return words.join('-');
    }
    return inputString;
}
function getUserLocation() {
    return new Promise((resolve, reject) => {
        fetch('https://api.ipdata.co?api-key=30640c0edf19dd10b9d718063d2bbd889f18af688154820fcc5581ae')
            .then((response) => response.json())
            .then((data) => {
            const country = data.country_name;
            const countryModified = country.trim().toLowerCase();
            const countryToMatch = addHyphensBetweenWords(countryModified);
            const countries = Array.from(document.querySelectorAll('#tabs-list a'));
            let hasMatch = false;
            countries.forEach((element) => {
                if (element.textContent) {
                    const elementCountry = element.getAttribute('data-country');
                    if (elementCountry !== null && elementCountry !== undefined) {
                        elementCountry.trim();
                        if (((elementCountry === 'minnesota' || elementCountry === 'new-york') &&
                            countryToMatch === 'united-states') ||
                            elementCountry === countryToMatch) {
                            hasMatch = true;
                        }
                    }
                }
            });
            if (hasMatch) {
                const countryPop = document.getElementById('no-location');
                const tabsWrapper = document.getElementById('pricing-tabs-wrapper');
                const tabLists = document.querySelectorAll("ul[role='tablist']");
                countryPop && countryPop.classList.remove('active');
                tabsWrapper && tabsWrapper.classList.remove('disabled');
                resolve(data);
            }
            else {
                resolve(false);
                const popupTitleElement = document.querySelector('.pricing-section__popup-title');
                if (popupTitleElement) {
                    const popupText = popupTitleElement.textContent && popupTitleElement.textContent.trim();
                    if (popupText) {
                        const firstPeriodIndex = popupText && popupText.indexOf('.');
                        if (firstPeriodIndex && firstPeriodIndex !== -1) {
                            const newTitle = popupText.slice(0, firstPeriodIndex) + country + popupText.slice(firstPeriodIndex);
                            popupTitleElement.textContent = newTitle;
                        }
                    }
                }
            }
        })
            .catch((error) => {
            console.log(`Error occurred while retrieving location: ${error}`);
            reject();
        });
    });
}
export default getUserLocation;
