function setSingleForm() {
    const singleCareerRoles = document.querySelector('#single-career-roles');
    if (!singleCareerRoles) {
        return;
    }
    function setSinglePositionInputValue() {
        var _a;
        const singlePositionInputs = singleCareerRoles.querySelectorAll('.single-position-input');
        const dataRolePosition = (_a = singleCareerRoles.querySelector('[data-role-position]')) === null || _a === void 0 ? void 0 : _a.getAttribute('data-role-position');
        if (dataRolePosition) {
            singlePositionInputs.forEach(singlePositionInput => {
                singlePositionInput.setAttribute('value', dataRolePosition);
            });
        }
    }
    function floatBTN() {
        const btn = singleCareerRoles.querySelector('#apply-button--sticky');
        const trigger = singleCareerRoles.querySelector('.single__apply-form .cta-button');
        if (trigger && btn) {
            const observer = new IntersectionObserver((entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        btn.classList.add('hidden');
                    }
                    else {
                        btn.classList.remove('hidden');
                    }
                });
            }, {
                threshold: 0.1
            });
            observer.observe(trigger);
        }
        const applyButtons = document.getElementsByClassName('apply-button');
        const applyClose = document.getElementById('apply_close');
        const wrapperNode = document.getElementById('main-wrapper');
        const applyFormWrapper = document.querySelector('.apply-form__wrapper');
        Array.from(applyButtons).forEach((element) => {
            const contactForm = document.querySelector('.wpcf7-form');
            element.addEventListener('click', (event) => {
                var _a;
                event.preventDefault();
                const grandparentElement = (_a = element.parentElement) === null || _a === void 0 ? void 0 : _a.parentElement;
                contactForm === null || contactForm === void 0 ? void 0 : contactForm.classList.remove('invalid');
                wrapperNode === null || wrapperNode === void 0 ? void 0 : wrapperNode.classList.add('apply');
            });
        });
        applyClose.addEventListener('click', (event) => {
            const contactForm = document.querySelector('.wpcf7-form');
            event.preventDefault();
            applyFormWrapper.scrollTop = 0;
            wrapperNode.classList.remove('apply');
            contactForm === null || contactForm === void 0 ? void 0 : contactForm.classList.remove('invalid');
        });
    }
    function initThankYouPopup() {
        document.addEventListener('wpcf7mailsent', function (event) {
            var _a;
            (_a = document.querySelector('#main-wrapper')) === null || _a === void 0 ? void 0 : _a.classList.add('sent');
            document.querySelector('.apply-form').style.display = 'none';
        }, false);
        const closeButton = document.querySelector('.thank-you__close');
        const closeCTA = document.querySelector('.thank-you__btn[aria-label="Close"]');
        function closePopup(closeBTN) {
            if (!closeBTN) {
                return;
            }
            closeBTN.addEventListener('click', (e) => {
                var _a;
                e.preventDefault();
                (_a = document.querySelector('#main-wrapper')) === null || _a === void 0 ? void 0 : _a.classList.remove('sent');
            });
        }
        closePopup(closeButton);
        closePopup(closeCTA);
    }
    setSinglePositionInputValue();
    floatBTN();
    initThankYouPopup();
}
export default setSingleForm;
